import { defineComponent, h } from "vue";
import type { VNode, PropType, Slot } from "vue";
import defu from "defu";
import { useForm } from "vee-validate";
import { VCol, VRow } from "vuetify/components";
import VDynamicFormField from "../private/VDynamicFormField";
import { cloneDeep, pick } from "lodash";
import type { FieldInputs } from "../../types";
import { parseFieldsInput } from "../../_utils";
import { provideForm } from "../../utils/forms";

export const props = {
  modelValue: { type: Object },
  loading: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  defaultProps: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
  defaults: { type: Object as PropType<any>, default: () => ({}) },
  inputs: { type: [Object, Array] as PropType<FieldInputs>, required: true },
  tag: { typ: String, default: "form" },
  validateOnBlur: { type: Boolean, default: true },
  validateOnChange: { type: Boolean, default: true },
  validateOnInput: { type: Boolean, default: true },
  validateOnModelUpdate: { type: Boolean, default: true },
  validateOnMount: { type: Boolean, default: false },
};

export const emits = [
  "update:modelValue",
  "update:valid",
  "update:dirty",
  "update:errors",
];

export default defineComponent({
  name: "VDynamicForm",
  props,
  setup(props, ctx) {
    const updatingLocal = ref(false);
    const updatingExternal = ref(false);

    const fields = computed(() =>
      parseFieldsInput(props.inputs!, props.defaultProps, {
        loading: props.loading,
        disabled: props.disabled,
        readonly: props.readonly,
        validateOnBlur: props.validateOnBlur,
        validateOnChange: props.validateOnChange,
        validateOnInput: props.validateOnInput,
        validateOnModelUpdate: props.validateOnModelUpdate,
      })
    );

    const schema = computed(() => fields.value.schema);

    const form = useForm({
      validationSchema: schema,
      validateOnMount: props.validateOnMount,
      initialValues: defu(props.defaults || {}, props.modelValue || {}),
    });

    const updateValues = (value: any) => {
      if (updatingLocal.value) return;
      updatingExternal.value = true;
      nextTick(() => {
        form.setValues(value);
        nextTick(() => {
          updatingExternal.value = false;
        });
      });
    };

    const emitValues = (values: any) => {
      if (updatingExternal.value) return;
      updatingLocal.value = true;
      nextTick(() => {
        const newDate = cloneDeep(values);
        const fieldKeys = Object.keys(fields.value.schema);
        ctx.emit("update:modelValue", {
          ...props.modelValue,
          ...pick(newDate, fieldKeys),
        });
        nextTick(() => {
          updatingLocal.value = false;
        });
      });
    };

    // bind external changes from model value
    // to form values
    watch(() => props.modelValue, updateValues);

    // bind form values to external model value
    watch(form.values, emitValues);

    watchEffect(() => {
      ctx.emit("update:valid", form.meta.value.valid);
      ctx.emit("update:dirty", form.meta.value.dirty);
      ctx.emit("update:errors", form.errors);
    });

    provideForm(form);

    // onMounted(() => {
    //   updateValues(props.modelValue);
    // });

    return {
      fields,
      validate: form.validate,
      validateField: form.validateField,
    };
  },
  methods: {
    getFields(slots: { readonly [x: string]: Slot | undefined }): VNode[] {
      const components: VNode[] = [];
      const lines = Object.entries(this.fields.lines).filter(
        ([_, inputs]) => !!inputs.filter((input) => !input.hidden).length
      );

      lines.forEach(([_line, inputs], index) => {
        const items = inputs
          .filter((input) => !input.hidden)
          .map((input, inputIndex) => {
            const node = h(
              VDynamicFormField as any,
              {
                // @ts-expect-error error type mismatch expected
                key: input.key,
                options: input,
                posTop: index == 0,
                posBottom: index == lines.length - 1,
                posLeft: inputIndex == 0,
                posRight: inputIndex == inputs.length - 1,
              },
              slots
            );

            return {
              input,
              node,
            };
          });

        const mapItemsToCol = () =>
          items.map((item) => h(VCol, { ...item.input.col }, () => item.node));

        if (items.length > 1) {
          components.push(
            h(VRow, { noGutters: true, class: "gap-2" }, mapItemsToCol)
          );
        } else {
          components.push(...items.map((item) => item.node));
        }
      });

      return components;
    },
  },
  render() {
    const { before, after, ...slots } = this.$slots;
    const nodes = this.getFields(slots);

    if (before) {
      // @ts-expect-error slot type is valid
      nodes.unshift(before());
    }
    if (after) {
      // @ts-expect-error slot type is valid
      nodes.push(after());
    }

    return h(this.tag, { class: "v-dynamic-form" }, nodes);
  },
});
