import type { Component } from "vue";
import {
  VCheckbox,
  VRadio,
  VRangeSlider,
  VSelect,
  VSlider,
  VSwitch,
  VTextarea,
  VTextField,
} from "vuetify/components";
import { VNumberInput } from "vuetify/labs/VNumberInput";
import type { CountryData, CountrySlug, Region } from "country-region-data";
import {
  get,
  groupBy,
  isString,
  isUndefined,
  max,
  pick,
  sortBy,
  upperFirst,
} from "lodash";
import type { FieldInput, FieldInputs, InputLine, Schema } from "./types";

type FieldDefaults = Pick<
  FieldInput,
  | "disabled"
  | "readonly"
  | "loading"
  | "validateOnBlur"
  | "validateOnChange"
  | "validateOnInput"
  | "validateOnModelUpdate"
>;

export const getInputComponent = (
  input?: string
): Component | string | undefined => {
  if (input == "text") return VTextField;
  if (input == "select") return VSelect;
  if (input == "checkbox") return VCheckbox;
  if (input == "slider") return VSlider;
  if (input == "range-slider") return VRangeSlider;
  if (input == "switch") return VSwitch;
  if (input == "textarea") return VTextarea;
  if (input == "number") return VNumberInput;
  if (input == "radio") return VRadio;
};

export const isEvent = (value: any): value is Event =>
  typeof value == "object" && value instanceof Event;
// export const isEvent = (value: any): value is Event =>
//   typeof value == 'object' && get(value, 'target')

export const error = (message: string): never => {
  throw new Error(`[v-dynamic-form] ${message}`);
};

export const castValue = (value: any, cast: FieldInput["cast"]): unknown => {
  if (cast) {
    if (cast === "boolean") {
      return !!value;
    }
    if (cast === "integer") {
      return parseInt(value);
    }
    if (cast === "number") {
      return Number(value);
    }
    if (cast === "string") {
      return String(value);
    }
  }
  return value;
};

const parseRules = (rules: FieldInput["rules"]): any => {
  if (Array.isArray(rules)) {
    return rules.join("|");
  }
  return rules;
};

const extractSchemaProps = (schema: Schema): any =>
  pick(schema.spec, ["label"]);

const isYupSchema = (schema: any): schema is Schema => {
  // isObject(inputSchema) && inputSchema instanceof Schema
  // TODO: allow yup schemas without yup as a dependency
  return false;
};

const extractFieldDefaults = (
  field: FieldInput,
  defaults: any
): FieldDefaults => {
  const extract = <K extends keyof FieldDefaults>(key: K): FieldDefaults[K] =>
    defaults[key] ||
    (!isUndefined(get(field.props, key))
      ? get(field.props, key)
      : get(defaults, key));

  return {
    loading: extract("loading"),
    disabled: extract("disabled"),
    readonly: extract("readonly"),
    validateOnBlur: extract("validateOnBlur"),
    validateOnChange: extract("validateOnChange"),
    validateOnInput: extract("validateOnInput"),
    validateOnModelUpdate: extract("validateOnModelUpdate"),
  };
};

export const parseFieldsInput = (
  inputs: FieldInputs,
  defaultProps: any,
  defaults: FieldDefaults
) => {
  const fields: FieldInput[] = [];

  const schema: Record<string, Schema | string | undefined> = {};
  const casts: Record<string, FieldInput["cast"]> = {};

  for (const key in inputs) {
    // @ts-expect-error inputs key value is known
    const input: FieldInput = Object.assign({}, inputs[key]);
    if (isString(key)) {
      if (!input.name) {
        input.name = upperFirst(key);
      }
      if (!input.key) {
        input.key = key;
      }
    }
    if (!input.hidden) {
      schema[input.key] = parseRules(input.rules);
    }
    fields.push(input);
  }

  const getComponent = ({ type, component }: FieldInput) => {
    if (component) return component;
    component = getInputComponent(type);
    return component || "input";
  };

  const groupItems = (items: InputLine[]): Record<string, InputLine[]> => {
    const n: number = max(items.map((item: any) => item.line || 0)) || 0;

    /**
     * Manual chain instead of _.chain because
     * lodash-es treeshaking brakes in prod
     */
    const chain = (x: InputLine[]) => {
      const a = x.map((item, i): InputLine => {
        if (item.line === undefined) {
          item.line = n + i;
        } else {
          item.line = Number(item.line) + n;
        }
        return item;
      });

      const b = sortBy(a, "line");
      const c = groupBy(b, "line");

      return c;
    };

    return chain(items);
  };

  const inputItems = fields.map((field): InputLine => {
    const key = field.key || field.name;
    if (!key) {
      error("Input field name or key is required");
    }

    const inputSchema = schema[String(key)];

    if (isYupSchema(inputSchema)) {
      field.props = { ...field.props, ...extractSchemaProps(inputSchema) };
    }

    if (field.cast) {
      casts[String(key)] = field.cast;
    }

    return {
      ...field,
      key: key!,
      label: field.label || field.props?.label,
      hidden: field.hidden || false,
      component: getComponent(field),
      ...extractFieldDefaults(field, defaults),
      props: { ...defaultProps, ...field.props },
    } as InputLine;
  });

  const lines = groupItems(inputItems);

  return {
    schema,
    lines,
    casts,
  };
};

export const filterCountries = (
  countries: CountryData[],
  priorityCountries: CountrySlug[],
  whitelist: CountrySlug[],
  blacklist: CountrySlug[]
) => {
  const countriesListedFirst: CountryData[] = [];
  let filteredCountries: CountryData[];

  if (whitelist.length > 0) {
    filteredCountries = countries.filter(
      ([, countrySlug]) => whitelist.indexOf(countrySlug) > -1
    );
  } else if (blacklist.length > 0) {
    filteredCountries = countries.filter(
      ([, countrySlug]) => blacklist.indexOf(countrySlug) === -1
    );
  } else {
    filteredCountries = countries;
  }

  if (priorityCountries.length > 0) {
    // ensure the countries are added in the order in which they are specified by the user
    priorityCountries.forEach((slug) => {
      const result = filteredCountries.find(
        ([, countrySlug]) => countrySlug === slug
      );
      if (result) {
        countriesListedFirst.push(result);
      }
    });

    filteredCountries = filteredCountries.filter(
      ([, countrySlug]) => priorityCountries.indexOf(countrySlug) === -1
    );
  }

  return countriesListedFirst.length
    ? [...countriesListedFirst, ...filteredCountries]
    : filteredCountries;
};

export const filterRegions = (
  regions: Region[],
  whitelistObject: string[],
  blacklistObject: string[],
  useName: boolean
): Region[] => {
  if (whitelistObject.length > 0) {
    regions = regions.filter(([name, shortCode]) => {
      return whitelistObject.includes(useName ? name : shortCode);
    });
  }
  if (blacklistObject.length > 0) {
    regions = regions.filter(([name, shortCode]) => {
      return !blacklistObject.includes(useName ? name : shortCode);
    });
  }

  return regions;
};
