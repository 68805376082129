import { error } from "../_utils";
import type { FormContext } from "../types";

export const provideForm = (form: FormContext) => {
  provide("__form__", form);
};

export const injectForm = () => {
  const form = inject("__form__");
  if (!form) {
    error("Form undefined");
  }
  return form as FormContext;
};
